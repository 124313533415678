<template>
  <div style="overflow: hidden ;touch-action: none;
-ms-touch-action: none;" class="Sidebar">
    <b-sidebar id="sidebar-1" class="bg-light1 text-dark1" shadow backdrop>
      <div class="px-4 py-2 text-left">
        <b-row>
          <b-col>
            <div class="mb-3">
              <router-link to="/"><img src="../img/Logo2.svg" alt="" style="width: 225px;" class="ml-3"></router-link>
            </div>
            <div class="mb-2 mt-3 ">
              <router-link to="/get-quote" class="navbar-text3 ">Get a quote </router-link>
            </div>
            <div class="mb-2 ">
              <router-link to="/freight-transportation" class="navbar-text3">Freight transportation </router-link>
            </div>
            <div class="mb-2">
              <a href="/contractors" target="_blank" class="navbar-text3">Contractors</a>
            </div>
            <div class="mb-2">
              <router-link to="/about-us" class="navbar-text3">About us</router-link>
            </div>
            <div class="mb-2">
              <router-link to="/contact-us" class="navbar-text3">Contact us</router-link>
            </div>
            <div class="mb-2">
              <router-link to="/blog" class="navbar-text3">Blog</router-link>
            </div>
            <b-row class="mt-3 ">
              <b-col cols="1">
                <img src="../img/formPh2.svg" width="17" height="17" style="margin-left:5px;margin-top:8px;color:#101549"
                  alt="">
              </b-col>
              <b-col cols="10" class="formLi">
                <a href="mailto:dispatch@pennsylvanialogisticsllc.com" target="_blank" class="RL ml-1"
                  style="font-size:18px;color:#101549">dispatch@pennsylvanialogisticsllc.com</a>
              </b-col>
            </b-row>
            <b-row class="mb-4">
              <b-col cols="1">
                <img src="../img/formMs2.svg" width="17" height="17" style="margin-left:5px;margin-top:4px;color:#101549"
                  alt="">
              </b-col>
              <b-col cols="10" class="formLi">
                <a href="tel:+1 (267) 507 7077" class="RL" style="font-size:18px;color:#101549">
                  <div class="ml-1">267-507-7077</div>
                </a>
              </b-col>
            </b-row>
            <div class="mt-2">
              <b-button class="btn-primery navbar-mr-btn" style="padding:13px 40px ;width:100%" v-b-modal.modalform>Call
                back</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
export default ({
})
</script>
<style>
.bg-light1 .bg-light {
  background-color: #fff !important;
}

.b-sidebar:not(.b-sidebar-right)>.b-sidebar-header .close {
  margin-left: auto;
  border: none;
  background: #fff;
}

.text-dark1 .text-dark {
  color: #000000 !important;
}
</style>
