<template>
  <div>
    <TopNavBar2></TopNavBar2>
    <pagenot></pagenot>
    <Footer1></Footer1>
  </div>
</template>

<script>
import TopNavBar2 from '../components/TopNavBar2.vue'
import pagenot from '../components/pagenot.vue'
import Footer1 from '../components/footer.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: [
        { rel: 'canonical', href: 'https://pennlogisticsllc.com/not-found' }
      ]
    }
  },
  data() {
    return {
      metaData: {

        en: {
          Homepage: {
            title: 'Page not found  | Pennsylvania Logistics', // set a title
            titleTemplate: '', // %s  required
            htmlAttrs: {
              lang: "en",
              amp: undefined // "amp" has no value
            },
            meta: [
              {
                'name': 'description',
                'content': " It looks like that page doesn't exist. Please check the URL and try again."
              },
              {
                'name': 'og:url',
                'content': 'https://pennlogisticsllc.com/not-found',
              },
              {
                'name': 'og:title',
                'content': 'Page not found  | Pennsylvania Logistics',
              },
              {
                'name': 'og:description',
                'content': " It looks like that page doesn't exist. Please check the URL and try again.",
              },
              {
                'name': 'og:type',
                'content': 'website',
              },


            ],
            link: [
              { rel: 'canonical', href: 'https://pennlogisticsllc.com/not-found' }
            ]
          },
        },
      },
    }
  },
  name: 'Home',
  components: {
    TopNavBar2, pagenot, Footer1
  },
  computed: {
    MetaData() {
      return this.metaData['en']['Homepage']
    }
  },
}
</script>