import Vue from 'vue'
import VueRouter from 'vue-router'
import Article from '../views/Article.vue'
import pagenot from '../views/Page-not-found.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/contractors',
    name: 'Contractors',
    component: () => import('../views/Contractors.vue')
  },
  {
    path: '/thank-you-quote',
    name: 'thank-you-quote',
    component: () => import('../views/Thank-you-quote.vue')
  },
  {
    path: '/thank-you',
    name: 'thank-you',
    component: () => import('../views/Thank-you.vue')
  },
  {
    path: '/privacy-policy',
    name: 'Privacy_policy',
    component: () => import('../views/Thank-you-quote.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Privacy-policy.vue')
  },
  {
    path: '/blog/:slug',
    name: 'Article',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/get-quote',
    name: 'Get-quote',
    component: () => import('../views/Get-quote.vue')
  },
  {
    path: '/freight-transportation',
    name: 'Service',
    component: () => import('../views/Service.vue')
  },
  {
    path: '/about-us',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/*',
    name: 'page-not-found',
    component: () => import('../views/Page-not-found.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth', };
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "active",
  base: process.env.BASE_URL,
  routes
})

export default router
