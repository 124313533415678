<template>
  <b-navbar toggleable="lg" class="navbar2 navbar ">
    <b-navbar-brand to="/" class="navbar-ml-logo"><img src="../img/Logo2.svg" alt=""></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/get-quote" class="navbar-text2">Get a quote</b-nav-item>
        <b-nav-item to="/freight-transportation" class="navbar-text2">Freight transportation</b-nav-item>
        <!-- <b-nav-item href="https://pennlogisticsllc.hr-portal.net/?referral=360ffebb-c3c2-4777-8d1b-6577a97c56fb"
          target="_blank" class="navbar-text">Contractors</b-nav-item> -->
        <b-nav-item to="/contractors" class="navbar-text2">Contractors</b-nav-item>
        <b-nav-item class="navbar-text2 drop">Company <i class="fas fa-angle-down st ml-2"></i>
          <div class="divdrop">
            <div class="divitem">
              <router-link to="/about-us" class="navbar-text atopN">About us</router-link>
            </div>
            <div class="divitem">
              <router-link to="/contact-us" class="navbar-text atopN">Contact us</router-link>
            </div>
            <div class="divitem">
              <router-link to="/blog" class="navbar-text atopN">Blog</router-link>
            </div>
          </div>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-button class="btn-primery navbar-mr-btn" style="padding:13px 40px ;" v-b-modal.modalform>Call back</b-button>
      </b-navbar-nav>
      <Modalform></Modalform>
    </b-collapse>
  </b-navbar>
</template>
<script>
import Modalform from "../components/Modalform.vue";
export default {
  components: { Modalform }
}
</script>
