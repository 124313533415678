import axios from 'axios';
const apiBaseUrl = 'https://pennsylvanialogisticsllc.com'
console.log('apiBaseUrl', apiBaseUrl)
const config = {
  baseURL: apiBaseUrl
};

const httpClient = axios.create(config);

const authInterceptor = config => {
  return config;
};
const loggerInterceptor = config => {
  return config;
};

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

httpClient.interceptors.response.use(
  response => {

    return response;
  },
);

export { httpClient };
