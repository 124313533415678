import Vue from 'vue'
import Vuex from 'vuex'
import { httpClient } from '../HttpClientV1/http-client'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    zip_from: null,
    zip_to: null,
    articles:[],
    article: {
      title: '',
      slug: '',
      content: '',
      meta_description: ''
    },
  },
  mutations: {
    setZips(state, payload){
      state.zip_from = payload.zip_from
      state.zip_to = payload.zip_to
    },
    updateArticles(state, articles){
      state.articles = articles
    },
    updateArticle(state, article){
      state.article = article
    }
  },
  actions: {
    setZips(ctx, payload){
      ctx.commit('setZips', payload)
    },
    fetchArticle(ctx, slug){

        httpClient
        .get('api/article?slug='+slug)
        .then((resp)=>{
          ctx.commit('updateArticle', resp.data.article)
        })
      
    },
    fetchArticles(ctx, limit = null){
      if(limit == null){

        httpClient
        .get('api/article')
        .then((resp)=>{
          ctx.commit('updateArticles', resp.data.article)
        })
      }
      else{
        httpClient
        .get('api/article?limit='+ limit)
        .then((resp)=>{
          ctx.commit('updateArticles', resp.data.article)
        })
      }
    }
  },
  getters:{
    getArticles(state){
      return state.articles
    },
    getArticle(state){
      return state.article
    },
    getZip(state){
      var data = {
        zip_from: state.zip_from,
        zip_to: state.zip_to
      }
      return data
    }
  },
  modules: {
  }
})
