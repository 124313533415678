<template>
        <div>
         <b-modal id="modalform" ref="my-modal" size="lg" class="modalform" hide-footer hide-header  title-sr-only:false>
         <b-row>
         <b-col>
          <h2 class="block2-h2 mt-2">Request a call back</h2>
         <p class="block3-p2 mt-4 mb-4">Enter your contact info and we will call you back.</p>
          <div class="div-colse">
                      <b-button @click="hideModal" class="close "  >
                         <b-icon icon="plus" class="closeplus"></b-icon>
                      </b-button>
                        </div>
           <b-form @submit.prevent="sendData">
                            <b-form-group >
                                <b-form-input placeholder="Name" v-model="name" class="blockS2-inp  "></b-form-input>
                            </b-form-group>
                             <b-form-group >
                                <b-form-input placeholder="Phone" v-model="phone" class="blockS2-inp  "></b-form-input>
                            </b-form-group>
                             <b-form-group >
                                <b-form-input placeholder="E-mail" v-model="email" class="blockS2-inp  "></b-form-input>
                            </b-form-group>
                             <b-form-group>
                                <b-form-textarea no-resize placeholder="Questions /comments" v-model="comment" class="blockS2-inp  " style="height:120px"></b-form-textarea>
                            </b-form-group>
                            <b-row class="ml-1">
                              <b-form-checkbox v-model="check"></b-form-checkbox> <p>I agree with <router-link to="/privacy-policy" class="RL">privacy policy</router-link>.</p>
                            </b-row>
                                <b-button class="btn-primery mt-3 mb-4" style="width:100%" type="submit">Submit</b-button>
                        </b-form>
                        <img src="../img/modal-img.svg" class="blockS2-cardT1" style="top:-80px;left:-65px" alt="">
                                <img src="../img/blockS2-cardT1.svg" class="blockS2-cardT1 modal-img2"  alt="">
         </b-col>
         </b-row>
         </b-modal>
         </div>
</template>
<script>
import { httpClient } from '../HttpClientV1/http-client'
export default {
    data(){
        return{
            name: null,
            comment: null,
            email:null,
            phone: null,
            check: true,
        }
    },
     methods:{
         hideModal() {
        this.$refs['my-modal'].hide()
      },
       sendData(){
           var data = {
               form_request:{
                    name: this.name,
                    quest: this.comment,
                    email:this.email,
                    phone: this.phone,  
               }
           }
           if(this.check){
            httpClient
            .post('api/form_request',  data)
            .then(()=>{
                this.name = this.comment = this.email = this.phone = null
                  this.$router.push('/thank-you')
            })
           }
           else{
               alert('Place agree with privacy policy')
           }
       }
    }
   
}
</script>