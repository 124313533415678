<template>
  <div id="app" class="mobile">
    <b-row class="Sidebar">
      <b-button v-b-toggle.sidebar-1 class="btn-side">
        <b-icon font-scale="2" style="width:100%;color: #fff;margin-top:5px" icon="justify"></b-icon>
      </b-button>
    </b-row>
    <router-view />
    <SideBar></SideBar>
  </div>
</template>
<script>
import SideBar from './components/SideBar.vue'
export default {
  components: { SideBar }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
