export const tnbanim = {
  methods: {
    anim() {
      let navbar2 = document.getElementById("navbar2");
      let card1 = document.getElementById("card1");
      let card2 = document.getElementById("card2");
      let card3 = document.getElementById("card3");
      let card4 = document.getElementById("card4");
      let card5 = document.getElementById("card5");
      let card6 = document.getElementById("card6");
      let card7 = document.getElementById("card7");
      let card8 = document.getElementById("card8");
      let card9 = document.getElementById("card9");
      let card10 = document.getElementById("card10");
      let card11 = document.getElementById("card11");
      let card12 = document.getElementById("card12");
      let card1block7 = document.getElementById("card1block7");
      let card2block7 = document.getElementById("card2block7");
      let card3block7 = document.getElementById("card3block7");
      let card4block7 = document.getElementById("card4block7");
      let card1block5 = document.getElementById("card1block5");
      let card1blockS4 = document.getElementById("card1blockS4");
      let card1blockAB2 = document.getElementById("card1blockAB2");
      let img1blockS2 = document.getElementById("img1blockS2");
      let img2blockS2 = document.getElementById("img2blockS2");
      let img3blockS2 = document.getElementById("img3blockS2");
      let img4blockS2 = document.getElementById("img4blockS2");
      let img5blockS2 = document.getElementById("img5blockS2");
      let cardblock31 = document.getElementById("cardblock31");
      let cardblock32 = document.getElementById("cardblock32");
      let cardblock33 = document.getElementById("cardblock33");
      let cardblock34 = document.getElementById("cardblock34");
      let cardblock35 = document.getElementById("cardblock35");
      let cardblock36 = document.getElementById("cardblock36");
      let cardblockAB41 = document.getElementById("cardblockAB41");
      let cardblockAB42 = document.getElementById("cardblockAB42");
      let cardblockAB43 = document.getElementById("cardblockAB43");
      let cardblockAB4div = document.getElementById("cardblockAB4div");
      window.document.onscroll = () => {
        console.log(window.scrollY)
        if (window.scrollY > 500) {
          navbar2.classList.remove('tnb2none')
          navbar2.classList.add('tnb2anim')
        }
        else {
          navbar2.classList.remove('tnb2anim')
          navbar2.classList.add('tnb2none')
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 700) {
            try {
              img1blockS2.classList.add('cardanimblock7card1')
              img2blockS2.classList.add('cardanimblock7card2')
              img3blockS2.classList.add('cardanimblock7card3')
              img4blockS2.classList.add('cardanimblock7card4')
              img5blockS2.classList.add('cardanimblock7card5')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth < 578) {

          try {
            if (window.scrollY > 900) {
              img1blockS2.classList.add('cardanimblock7card1')
            }
            if (window.scrollY > 1300) {
              img2blockS2.classList.add('cardanimblock7card1')
            }
            if (window.scrollY > 1700) {
              img3blockS2.classList.add('cardanimblock7card1')
            }
            if (window.scrollY > 2100) {
              img4blockS2.classList.add('cardanimblock7card1')
            }
            if (window.scrollY > 2500) {
              img5blockS2.classList.add('cardanimblock7card1')
            }
          }
          catch {
            console.log('a')
          }

        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 500) {
            try {
              card1blockAB2.classList.add('cardanimblock71')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth < 578) {
          if (window.scrollY > 900) {
            try {
              card1blockAB2.classList.add('cardanimblock71')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 500) {
            try {
              card1blockAB2.classList.add('cardanimblock71')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth < 578) {
          if (window.scrollY > 3300) {
            try {
              cardblockAB41.classList.add('cardanimblock71')
              cardblockAB42.classList.add('cardanimblock72')
              cardblockAB43.classList.add('cardanimblock73')
              cardblockAB4div.classList.add('cardanimblock74')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 2000) {
            try {
              cardblockAB41.classList.add('cardanimblock71')
              cardblockAB42.classList.add('cardanimblock72')
              cardblockAB43.classList.add('cardanimblock73')
              cardblockAB4div.classList.add('cardanimblock74')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth < 578) {
          if (window.scrollY > 14100) {
            try {
              card1blockS4.classList.add('cardanimblock71')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 6500) {
            try {
              card1blockS4.classList.add('cardanimblock71')
            }
            catch {
              console.log('a')
            }
          }
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 3400) {
            // this solution for fix errors on contractors page
            card1block5 ? card1block5.classList.add('cardanimblock71') : null
          }
        }
        if (document.body.clientWidth < 578) {
          if (window.scrollY > 6600) {
            card1block5.classList.add('cardanimblock71')
          }
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 5000 && card1block7) {
            card1block7.classList.remove('cardanimblock7')
            card2block7.classList.remove('cardanimblock7')
            card3block7.classList.remove('cardanimblock7')
            card4block7.classList.remove('cardanimblock7')
            card1block7.classList.add('cardanimblock71')
            card2block7.classList.add('cardanimblock72')
            card3block7.classList.add('cardanimblock73')
            card4block7.classList.add('cardanimblock74')
          }
        }
        else if (document.body.clientWidth < 578) {
          if (window.scrollY > 9500) {
            card1block7.classList.remove('cardanimblock7')
            card2block7.classList.remove('cardanimblock7')
            card3block7.classList.remove('cardanimblock7')
            card4block7.classList.remove('cardanimblock7')
            card1block7.classList.add('cardanimblock71')
            card2block7.classList.add('cardanimblock72')
            card3block7.classList.add('cardanimblock73')
            card4block7.classList.add('cardanimblock74')
          }
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 1300) {
            cardblock31.classList.add('cardanimblock71')
            cardblock32.classList.add('cardanimblock72')
            cardblock33.classList.add('cardanimblock73')
            cardblock34.classList.add('cardanimblock74')
            cardblock35 ? cardblock35.classList.add('cardanimblock75') : null
            cardblock36 ? cardblock36.classList.add('cardanimblock76') : null
          }
        }
        else if (document.body.clientWidth < 578) {
          if (window.scrollY > 1600) {
            cardblock31.classList.add('cardanimblock71')
          }
          if (window.scrollY > 1800) {
            cardblock32.classList.add('cardanimblock71')
          }
          if (window.scrollY > 2000) {
            cardblock33.classList.add('cardanimblock71')
          }
          if (window.scrollY > 2200) {
            cardblock34.classList.add('cardanimblock71')
          }
          if (window.scrollY > 2400) {
            cardblock35 ? cardblock35.classList.add('cardanimblock71') : null
          }
          if (window.scrollY > 2600) {
            cardblock36 ? cardblock36.classList.add('cardanimblock71') : null
          }
        }
        if (document.body.clientWidth >= 578) {
          if (window.scrollY > 4050) {
            card1.classList.add('cardanimblock71')
            card2.classList.add('cardanimblock72')
            card3.classList.add('cardanimblock73')
            card4.classList.add('cardanimblock74')
            card5.classList.add('cardanimblock75')
            card6.classList.add('cardanimblock76')
            card7.classList.add('cardanimblock73')
            card8.classList.add('cardanimblock74')
            card9.classList.add('cardanimblock75')
            card10.classList.add('cardanimblock76')
            card11.classList.add('cardanimblock77')
            card12.classList.add('cardanimblock78')
          }
        }
        if (document.body.clientWidth < 578) {
          if (window.scrollY > 7650) {
            card1.classList.add('cardanimblock71')
            card2.classList.add('cardanimblock72')
            card3.classList.add('cardanimblock72')
            card4.classList.add('cardanimblock73')
            card5.classList.add('cardanimblock73')
            card6.classList.add('cardanimblock74')
            card7.classList.add('cardanimblock74')
            card8.classList.add('cardanimblock75')
            card9.classList.add('cardanimblock75')
            card10.classList.add('cardanimblock76')
            card11.classList.add('cardanimblock76')
            card12.classList.add('cardanimblock77')
          }
        }
      }
    }
  }
}/*& window.scrollY <  600*/
