<template>
  <div>
    <TopNavBar2 class="tnb2"></TopNavBar2>
    <b-container fluid class="block8">
      <b-container class="text-left">
        <b-row align-v="start" align-h="center">
          <b-col md="10" ofset-md="1">
            <p class="block3-p1 text-left mtM" style="opacity: 1!important">
              <router-link to="/blog" class="RL">Pennsylvania Logistics BLOG</router-link>
              | {{ Article.created }}
            </p>
            <h1 class="mtM block2-h2" style="color:#000">
              <strong>{{ Article.title }}</strong>
            </h1>
            <div style=" margin-bottom:240px" v-html="Article.content"></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card class="div-block7 divarticle">
              <b-row>
                <b-col md="9" class="text-left">
                  <img src="../img/block7-img-card2.svg" class="block7-img-card2" alt />
                  <p
                    class="div-p ml-3 mt-5 pt-4"
                    style="z-index:7;"
                  >Let us tell how much time, effort and money you can save with Pennsylvania Logistics</p>
                </b-col>
                <b-col md="3">
                  <div class="mt-5 pt-5">
                    <router-link to="/get-quote">
                      <b-button class="btn-three">Get a freight quote</b-button>
                    </router-link>
                  </div>
                  <img src="../img/block7-img-card1.svg" class="block7-img-card1" alt />
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <form1></form1>
    <Footer1></Footer1>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import TopNavBar2 from '../components/TopNavBar2.vue'
import form1 from '../components/form.vue'
import testA from '../components/testA.vue'
import Footer1 from '../components/footer.vue'
export default {
  metaInfo() {
    return {
      title: this.MetaData['title'], // set a title
      titleTemplate: this.MetaData['titleTemplate'], // %s  required
      htmlAttrs: this.MetaData['htmlAttrs'],
      meta: this.MetaData['meta'],
      link: this.MetaData['link']
    }
  },
  name: 'Home',
  data() {
    return {
      slug: this.$route.params.slug,
      
      
    }
  },
  components: {
    TopNavBar2, form1, Footer1, testA
  },
  methods: {
    ...mapActions(['fetchArticle']),
    allArticles() {
      this.$router.push('/blog')
    },
    loadArticle(slug) {
      this.$router.push('/blog/' + slug)
    }
  },
  mounted() {
    console.log('META',this.MetaData)
    this.fetchArticle(this.$route.params.slug)
  },
  computed: {
    
    ...mapGetters(['getArticle']),
    Article() {
      return this.getArticle
    },
    MetaData() {
      var data = {
        title: this.Article.title, // set a title
        titleTemplate: '', // %s  required
        htmlAttrs: {
            lang: "en",
            amp: undefined // "amp" has no value
        },
        meta: [
            {
                'name': 'keywords',
                'content': 'freight transportation services, ftl shipping, ltl shipping, trucking, truckload, transportation company',
            },
            {
                'name': 'description',
                'content':this.Article.meta_description,
            },
            {
                'name': 'og:url',
                'content': 'https://pennlogisticsllc.com/blog/' + this.Article.slug,
            },
            {
                'name': 'og:title',
                'content': this.Article.title,
            },
            {
                'name': 'og:description',
                'content': 'Informative trucking articles & news concerning all aspects of the freight shipping industry. Get useful hints and tips from our freight shipping blog.',
            },
            {
                'name': 'og:type',
                'content': 'website',
            },

        ],
        link: [
            { rel: 'canonical', href: 'https://pennlogisticsllc.com/blog/' + this.Article.slug }
        ],
      }
      return data
    },
  }
}
</script>