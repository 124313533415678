import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMeta from 'vue-meta'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
import './assets/style/app.css'
Vue.config.productionTip = false
import "./assets/fontawesome-free/css/all.min.css";
import vSelect from "vue-select";
//подключение деволтного стиля v-select
import 'vue-select/dist/vue-select.css';
import { tnbanim } from './assets/style/tnbanim'
Vue.component("v-select", vSelect);
Vue.mixin(tnbanim)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false
})
new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
